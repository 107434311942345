<template>
  <div class="main-view">
    <el-radio-group
      v-model="table.params.status"
      @change="handleTabs"
      style="margin-bottom: 10px"
    >
      <el-radio-button :label="1">待处理({{ table.total_1 }})</el-radio-button>
      <el-radio-button :label="2">已处理({{ table.total_2 }})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="标题搜索:" prop="keyword">
          <el-input
            clearable
            v-model="table.params.keyword"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="工种类型:" prop="kinds">
          <el-select
            v-model="table.params.kinds"
            clearable
            placeholder="请选择工种类型"
          >
            <el-option
              v-for="item in setKinds"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请时间" prop="selDate">
          <el-date-picker
            v-model="table.params.selDate"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="申请人账号:" prop="account">
          <el-input
            clearable
            v-model="table.params.account"
            placeholder="请输入申请人账号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            class="theneBtn"
            type="primary"
            icon="el-icon-search"
            @click="onSearch"
            >查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button
        size="mini"
        type="primary"
        plain
        icon="el-icon-download"
        @click="handleExport"
        >全部导出</el-button
      >
      <el-button
        size="mini"
        type="primary"
        plain
        icon="el-icon-download"
        @click="handleExportBatch"
        >批量导出</el-button
      >
    </div>

    <VTable
      has-pagionation
      title=""
      addText="添加"
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page="table.params.page"
      :pageSize="table.params.count"
      :total="table.total"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:type="{ row }">
        <span>{{ row.type === 1 ? "老板" : "师傅" }}</span>
      </template>
      <template v-slot:status="{ row }">
        <span>{{
          row.status === 0 ? "待处理" : row.status === 1 ? "同意" : "拒绝"
        }}</span>
      </template>
      <template v-slot:action="{ row }">
        <el-button
          v-if="table.params.status === 1"
          type="text"
          @click="showDetail(row, table.params.status)"
          >处理</el-button
        >
        <el-button
          v-if="table.params.status === 2"
          type="text"
          icon="el-icon-view"
          @click="showDetail(row, table.params.status)"
          >查看</el-button
        >
      </template>
    </VTable>

    <detail ref="detail" @refresh="getTable"></detail>
  </div>
</template>

<script>
import VTable from "@/components/VTable";
import Detail from "./components/Detail.vue";
import { mixinTable } from "@/mixins/table.js";
import { exportData } from "@/util";
import { mapGetters } from "vuex";
export default {
  name: "ArticleList",
  components: {
    VTable,
    Detail,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(["setKinds"]),
  },
  data() {
    return {
      field: [
        { name: "order_no", label: "工单编号", width: "170", hidden: false },
        { name: "kinds_str", label: "工种类型", hidden: false },
        { name: "title", label: "招工标题", width: "170", hidden: false },
        { name: "name", label: "申请介入人", hidden: false },
        { name: "account", label: "账号", width: "120", hidden: false },
        { name: "type", label: "用户角色", hidden: false },
        {
          name: "desc",
          label: "介入原因",
          width: "160",
          hidden: false,
          showTooltip: true,
        },
        { name: "create_time", label: "申请时间", width: "160", hidden: false },
        { name: "status", label: "处理结果", hidden: false },
        {
          name: "action",
          label: "操作",
          fixed: "right",
          width: "120",
          hidden: false,
        },
      ],
      table: {
        loading: false,
        total_1: "",
        total_2: "",
        params: {
          keyword: "",
          kinds: "",
          account: "",
          status: 1,
          start_time: "",
          end_time: "",
          page: 1,
          count: 10,
        },
        data: [],
        total: 0,
      },
      delIds: [],
    };
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      let _date = this.table.params.selDate;
      this.table.params.start_time = _date ? _date[0] : "";
      this.table.params.end_time = _date ? _date[1] : "";
      let _params = {
        keyword: this.table.params.keyword,
        kinds: this.table.params.kinds,
        account: this.table.params.account,
        status: this.table.params.status,
        start_time: this.table.params.start_time,
        end_time: this.table.params.end_time,
        page: this.table.params.page,
        count: this.table.params.count,
      };
      this.$http
        .get("admin/flex_intervene/list", { params: _params })
        .then((res) => {
          if (res.code === 1) {
            this.table.data = res.data.list;
            this.table.total = res.data.total;
            this.table.total_1 = res.data.total_1;
            this.table.total_2 = res.data.total_2;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    showEdit(row) {
      let dom = this.$refs.edit;
      dom.toggle(true);
      if (!!row.id) {
        dom.getDetail(row);
      }
      dom = null;
    },
    // 查看
    showDetail(row, curStatus) {
      let dom = this.$refs.detail;
      dom.toggle(true);
      dom.getDetail(row, curStatus);
      dom = null;
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done();
          } else {
            done();
          }
        },
      }).catch(() => {});
    },
    delete(rowid) {
      this.$http.post("/admin/article/delete", { id: rowid }).then((res) => {
        if (res.code === 1) {
          this.$message.success("操作成功！");
          this.getTable();
        } else {
          this.$message.error(res.data);
        }
      });
    },
    handleSelectionChange(value) {
      this.delIds = [];
      if (value.length > 0) {
        value.forEach((v) => {
          this.delIds.push(v.id);
        });
      }
    },
    handleExport() {
      let _date = this.table.params.selDate;
      let _params = {
        keyword: this.table.params.keyword,
        kinds: this.table.params.kinds,
        account: this.table.params.account,
        status: this.table.params.status,
        start_time: _date ? _date[0] : "",
        end_time: _date ? _date[1] : "",
      };
      exportData(_params, "/admin/flex_intervene/export");
    },
    // 批量
    handleExportBatch() {
      if (this.delIds.length > 0) {
        let _date = this.table.params.selDate;
        let _params = {
          ids: this.delIds.join("|"),
          keyword: this.table.params.keyword,
          kinds: this.table.params.kinds,
          account: this.table.params.account,
          status: this.table.params.status,
          start_time: _date ? _date[0] : "",
          end_time: _date ? _date[1] : "",
        };
        exportData(_params, "/admin/flex_intervene/export");
      } else {
        this.$message.warning("请选择需要导出的数据");
      }
    },
  },
};
</script>

<style>
.el-tooltip__popper.is-dark {
  max-width: 800px;
}
</style>