<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button style="margin:20px;" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <p style="text-align: left;">工单编号: {{dataInfo.order.order_no}}</p>
      <div class="info-top">
        <div style="text-align:left;">
          <p>发布者：{{dataInfo.order.name}}&nbsp;&nbsp;{{dataInfo.order.sex === 0 ? '' : (dataInfo.order.sex === 1 ? '男' : '女')}}</p>
          <p>联系电话：{{dataInfo.order.phone}}</p>
        </div>
        <el-avatar
          style="width: 80px; height: 80px;margin-left:50px"
          shape="square"
          :src="dataInfo.order.avatar"
          fit="cover">
          <img src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
        </el-avatar>
      </div>
      <el-descriptions title="工单信息" :column="2" border style="margin-top:20px">
        <el-descriptions-item label="选择工人">{{dataInfo.order.work_name}}</el-descriptions-item>
        <el-descriptions-item label="性别">{{dataInfo.order.work_sex === 0 ? '' : (dataInfo.order.sex === 1 ? '男' : '女')}}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{dataInfo.order.work_phone}}</el-descriptions-item>
        <el-descriptions-item label="报名时间">{{dataInfo.order.apply_time}}</el-descriptions-item>
        <el-descriptions-item label="选择时间">{{dataInfo.order.choose_time}}</el-descriptions-item>
        <el-descriptions-item label="工单金额">¥{{dataInfo.order.total_amount}}&nbsp;&nbsp;{{settleType[dataInfo.order.settle_type]}}</el-descriptions-item>
        <!-- <el-descriptions-item label="服务类型">{{dataInfo.order.service_type === 1 ? '包工包料' : '包工不包料'}}</el-descriptions-item>
        <el-descriptions-item label="预付金额">¥{{dataInfo.order.prepay_amount}}</el-descriptions-item>
        <el-descriptions-item label="尾款金额">¥{{dataInfo.order.total_amount - dataInfo.order.prepay_amount}}</el-descriptions-item>
        <el-descriptions-item label="确认时间">{{dataInfo.order.check_time}}</el-descriptions-item> -->
        <el-descriptions-item label="状态">{{statusOpt[dataInfo.order.status]}}</el-descriptions-item>
      </el-descriptions>
      <!-- 雇主或工人申请退款 -->
      <p class="p-title">协商记录</p>
      <p class="i-title">{{dataInfo.order.cancel_type === 0 ? '未取消' : (dataInfo.order.cancel_type === 1 ? '雇主申请退款' : '工人申请取消')}}</p>
      <el-descriptions  :column="2" border style="margin-top:10px">
        <el-descriptions-item label="申请时间" :labelStyle='labelStyle'>{{dataInfo.log.apply_time}}</el-descriptions-item>
        <el-descriptions-item :label="dataInfo.order.cancel_type === 1 ? '退款原因' : '取消原因'" :labelStyle='labelStyle'>{{dataInfo.log.refund_reason}}</el-descriptions-item>
        <el-descriptions-item v-if="dataInfo.order.cancel_type === 1" label="退款金额" :labelStyle='labelStyle'>¥{{dataInfo.log.refund_amount}}</el-descriptions-item>
        <el-descriptions-item v-if="dataInfo.order.cancel_type === 2" label="工单金额" :labelStyle='labelStyle'>¥{{dataInfo.order.total_amount}}</el-descriptions-item>
        <el-descriptions-item label="补充说明" :labelStyle='labelStyle'>{{dataInfo.log.desc}}</el-descriptions-item>
      </el-descriptions>
      <!-- 上传凭证有图片才显示 -->
      <template v-if="dataInfo.log.refund_image.length > 0">
        <p class="i-title">上传凭证</p>
        <el-descriptions :column="2" :colon="false" style="margin-top:10px">
          <el-descriptions-item label="">
            <span v-for="img in dataInfo.log.refund_image" :key="img" style="margin-right:10px;">
              <el-image
                style="width: 100px; height: 100px"
                :src="img"
                fit="cover"
                :preview-src-list="dataInfo.log.refund_image"></el-image>
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </template>

      <!-- 工人或师傅拒绝 -->
      <p class="i-title">{{dataInfo.order.cancel_type === 0 ? '未取消' : (dataInfo.order.cancel_type === 1 ? '工人拒绝理由' : '雇主拒绝理由')}}</p>
      <el-descriptions title="" :column="2" border style="margin-top:10px">
        <el-descriptions-item label="拒绝时间" :labelStyle='labelStyle'>{{dataInfo.log.refuse_time}}</el-descriptions-item>
        <el-descriptions-item label="拒绝原因" :labelStyle='labelStyle'>{{dataInfo.log.refuse_reason}}</el-descriptions-item>
        <el-descriptions-item label="补充说明" :labelStyle='labelStyle'>{{dataInfo.log.desc}}</el-descriptions-item>
      </el-descriptions>
      <!-- 平台介入信息 -->
      <p class="i-title">{{dataInfo.order.cancel_type === 0 ? '未取消' : (dataInfo.order.cancel_type === 1 ? '雇主申请平台介入' : '工人申请平台介入')}}</p>
      <el-descriptions title="" :column="1" border style="margin-top:10px">
        <el-descriptions-item label="申请时间" :labelStyle='labelStyle'>{{dataInfo.cancel.deal_time}}</el-descriptions-item>
        <el-descriptions-item label="申请原因" :labelStyle='labelStyle'>{{dataInfo.cancel.refund_reason}}</el-descriptions-item>
        <el-descriptions-item label="补充说明" :labelStyle='labelStyle'>{{dataInfo.cancel.desc}}</el-descriptions-item>
        <el-descriptions-item label="照片" :labelStyle='labelStyle'>
          <span v-for="img in dataInfo.cancel.refund_image" :key="img" style="margin-right:10px;">
            <el-image
              style="width: 60px; height: 60px"
              :src="img"
              fit="cover"
              :preview-src-list="dataInfo.cancel.refund_image"></el-image>
          </span>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions title="招工信息" :column="1" border style="margin-top:20px">
        <el-descriptions-item label="发布时间">{{dataInfo.job.create_time}}</el-descriptions-item>
        <el-descriptions-item label="所属工种">{{dataInfo.job.kinds_str}}</el-descriptions-item>
        <el-descriptions-item label="招工标题">{{dataInfo.job.title}}</el-descriptions-item>
        <el-descriptions-item label="工作描述">{{dataInfo.job.content}}</el-descriptions-item>
        <el-descriptions-item label="用工时间">{{dataInfo.job.start_time}}至{{dataInfo.job.end_time}}</el-descriptions-item>
        <el-descriptions-item label="用工地址">{{dataInfo.job.address}}</el-descriptions-item>
        <el-descriptions-item label="联系人">{{dataInfo.job.contact_name}}&nbsp;&nbsp;{{dataInfo.job.contact_phone}}</el-descriptions-item>
      </el-descriptions>

      <el-descriptions title="处理结果" :column="1" border style="margin-top:20px" v-if="dataInfo.cancel.status > 0">
        <el-descriptions-item label="处理时间" :labelStyle='labelStyle'>{{dataInfo.cancel.deal_time}}</el-descriptions-item>
        <el-descriptions-item label="处理结果" :labelStyle='labelStyle'>{{dataInfo.cancel.status === 0 ? '待处理' : (dataInfo.cancel.status === 1 ? '同意' : '拒绝')}}</el-descriptions-item>
        <!-- 只有拒绝了才有拒绝理由 -->
        <el-descriptions-item v-if="dataInfo.cancel.status === 2" label="拒绝理由" :labelStyle='labelStyle'>{{dataInfo.cancel.refuse_reason}}</el-descriptions-item>
      </el-descriptions>
    </div>

    <!-- 待处理状态才展示处理按钮 -->
    <el-row v-if="curStatus === 1" type="flex" justify="center" style="margin-top:10px;">
      <el-button @click='toggle(false)'>返回</el-button>
      <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click="handleAcce">处理</el-button>
    </el-row>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="reset"
      append-to-body>
      <el-form
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='top'
        :model='form.data'
        :rules='form.rules'
      >
        <el-form-item prop="status" label="">
          <!-- 雇主（退款）， 工人（取消） -->
          <el-radio-group v-model="form.data.status">
            <el-radio :label="1">{{dataInfo.order.cancel_type === 1 ? '同意退款' : '同意申请'}}</el-radio>
            <el-radio :label="2">{{dataInfo.order.cancel_type === 1 ? '拒绝退款' : '拒绝申请'}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="form.data.status === 1">
          <!-- 只有雇主申请的有退款金额 -->
          <el-form-item v-if="dataInfo.order.cancel_type === 1" prop="amount" label="退款金额">
            <el-input
              placeholder="请输入内容"
              v-model="form.data.amount">
              <span slot="prefix" >￥</span>
            </el-input>
          </el-form-item>
        </template>
        <el-form-item v-else prop="refuse_reason" label="拒绝理由">
          <el-input
            v-model="form.data.refuse_reason"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 6}"
            placeholder="请输入拒绝理由"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="this.form.loading" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  
  </div>
</template>

<script>
import { REG_EXP } from '@/util'
export default {
  name: 'ApplyDetail',
  data() {
    return {
      visible: false,
      dataInfo: {
        order: {},
        log: {
          refund_image: []
        },
        job: {},
        cancel: {
          refund_image: []
        },
      },
      status: 1,
      form: {
        loading: false,
        data: {
          status: 1,
          amount: '',
          refuse_reason: '',
        },
        rules:{
          amount: [
            { required: true, message: '请输入退款金额',  trigger: 'change' },
            { pattern: REG_EXP.money, message: "请填写正确的金额格式，如：188.88", trigger: "blur"}
          ]
        },
      },
      dialogVisible: false,
      settleType: {
        1:'日结',
        2:'项目结',
        3:'可议'
      },
      statusOpt:{
        1:'待确认',
        2:'待开工',
        3:'待完工',
        4:'待结算',
        5:'已完成',
        6:'已结束',
      },
      labelStyle: { 'width': '160px' },
      curId: '',
      curStatus: '',
    }
  },
  methods: {
    // 获取详情
    getDetail(row, curStatus) {
      this.curStatus = curStatus;
      this.isChange = true
      this.curId = row.id
      this.$http.get('/admin/flex_intervene/info', {params:{id:row.id}}).then(res => {
        if(res.code === 1) {
          this.dataInfo = res.data;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false
      // this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    // 处理
    handleAcce() {
      this.dialogVisible = true;
      this.form.data.id = this.curId;
      this.form.data.amount = this.dataInfo.log.refund_amount;
    },
    // 提交
    confirm() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {}
          if(this.form.data.status === 1) {
            _params = {
              id: this.form.data.id,
              status: this.form.data.status,
              amount: this.dataInfo.order.cancel_type === 1 ? this.form.data.amount : '', // 如果是工人取消没有退款金额
            }
          } else {
            _params = {
              id: this.form.data.id,
              status: this.form.data.status,
              refuse_reason: this.form.data.refuse_reason,
            }
          }
          if(this.form.data.amount > this.dataInfo.log.refund_amount) {
            this.$message.warning(`退款金额不能大于${this.dataInfo.log.refund_amount}元`)
            return
          }
          this.form.loading = true;
          this.$http.post('/admin/flex_intervene/operation', _params).then(res => {
            if(res.code === 1) {
              this.dialogVisible = false;
              this.toggle(false)
              this.$emit('refresh')
            } else {
              this.$message.error(res.msg)
            }
          }).finally(() => {
            this.form.loading = false;
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;
  .main {
    width: 80%;
    margin: 0 auto;
    text-align: center;

    .info-top{
      display: flex;
      justify-content: start;
      align-items: center;
    }

    .title {
      font-size: 28px;
      font-weight: bold;
    }
    .time {
      font-size: 18px;
      color: #8C8C8C;
      margin-bottom: 20px;
    }
  }
  
}
.portrait {
  text-align: center;
}
.portrait ::v-deep .el-avatar--large {
  width: 60px;
  height: 60px;
  line-height: 40px;
}
.p-title{
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #303133;
}
.i-title {
  text-align: left;
  font-size: 14px;
  color: #909399;
}
</style>